<template>
    <template v-if="items && items.length">
        <div class="base-table-wrapper">
            <table
                class="base-table"
                :data-table-variant="dark ? 'dark' : 'light'"
                @vue:mounted="($el) => emits('table-mounted', $el.el)"
                @vue:unmounted="() => emits('table-unmounted')"
            >
                <thead v-if="!hideHeader">
                    <tr :class="{ 'rt-sticky-headers': stickyHeaders }">
                        <th
                            v-for="(column, index) in columns"
                            :key="column.key"
                            class="base-table-cell"
                            :class="[
                                generateHeaderAlignmentClass<T>(items, column),
                                { 'rt-sticky': stickyColumn && index === 0 },
                                { 'dark': darkHeadings },
                                column.columnClass
                            ]"
                        >
                            <span
                                v-if="column.sortable"
                                class="relative group"
                            >
                                <button
                                    class="inline-flex gap-2 items-center"
                                    style="text-align: inherit;"
                                    @click="updateSorting(column)"
                                >
                                    <slot
                                        :name="`header_${column.key as string}`"
                                        :header="column"
                                    >
                                        {{ column?.label ? $t(column.label) : '' }}
                                    </slot>
                                </button>
                                <div class="absolute inline-block left-full top-1/2 transform -translate-y-1/2 my-0 mx-2">
                                    <span
                                        v-if="currentSort && column.key === currentSort.key"
                                        :class="dark ? 'text-gray-900' : 'text-white'"
                                        class="shrink-0"
                                    >
                                        <OhVueIcon
                                            name="fa-arrow-up"
                                            scale=".75"
                                            :class="[currentSort.order === SortOrder.Desc ? 'rotate-180' : '']"
                                            class="duration-300"
                                            :color="dark ? '#000000' : '#fec301'"
                                        />
                                    </span>
                                    <OhVueIcon
                                        v-else
                                        scale=".75"
                                        name="fa-arrow-up"
                                        color="#989898"
                                        class="opacity-0 group-hover:opacity-100 h-3 shrink-0"
                                    />
                                </div>
                            </span>
                            <template v-else>
                                <slot
                                    :name="`header_${column.key as string}`"
                                    :header="column"
                                >
                                    {{ column?.label ? $t(column.label) : '' }}
                                </slot>
                            </template>
                        </th>
                        <th scope="col" />
                    </tr>
                </thead>
                <thead v-if="totals && totalsAtTop">
                    <TotalRow
                        :columns
                        :totals
                        :items
                    >
                        <template
                            v-for="(_, name) in $slots"
                            #[name]="slotData"
                        >
                            <slot
                                :name
                                v-bind="slotData"
                            />
                        </template>
                    </TotalRow>
                </thead>
                <slot

                    name="body"
                    :items
                    :headers="columns"
                    :show-expand
                >
                    <tbody class="order-1">
                        <template
                            v-for="(item, index) in items"
                            :key="index"
                        >
                            <slot
                                name="row"
                                :item
                                :headers="columns"
                                :show-expand
                            />
                        </template>
                    </tbody>
                </slot>
                <tfoot v-if="totals && !totalsAtTop">
                    <TotalRow
                        :columns
                        :totals
                        :items
                    >
                        <template
                            v-for="(_, name) in $slots"
                            #[name]="slotData"
                        >
                            <slot
                                :name
                                v-bind="slotData"
                            />
                        </template>
                    </TotalRow>
                </tfoot>
            </table>
        </div>
    </template>
    <template v-else>
        <div class="flex justify-center font-semibold items-center h-fit bg-zinc-100 rounded-lg my-3 min-h-[56px]">
            <span v-if="isLoading">{{ $t('general.loading') }}</span>
            <slot
                v-else
                name="no_result"
            >
                {{ noResultsTranslatedMessage }}
            </slot>
        </div>
    </template>
</template>

<script setup lang="ts" generic="T extends { [key: string]: any | any[] }">
import { SortOrder } from '@/Enums/SortOrder';
import { TableHeader, TableSort, TableTotal } from '@/Models/Components/BaseTableContract';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import TotalRow from '@/Components/Core/Table/TotalRow.vue';
import { generateHeaderAlignmentClass } from '@/Utils/TableHelper';
import { addIcons, OhVueIcon } from 'oh-vue-icons';
import { FaArrowUp } from 'oh-vue-icons/icons';
import { Sort } from '@/Utils/Filters/FilterHelper';
import { generateSort } from '@/Utils/SortHelper';

addIcons(FaArrowUp);

// eslint-disable-next-line no-undef
const sort = defineModel<Sort<T>>('sort');

const { t } = useI18n();

const {
    headers,
    items = [],
    totals = null,
    showExpand = false,
    dark = false,
    darkHeadings = false,
    stickyColumn = false,
    stickyHeaders = false,
    noResultsMessage,
    isLoading = false,
    totalsAtTop = false,
    hideHeader = false
} = defineProps<{
    // eslint-disable-next-line no-undef
    headers: TableHeader<T>[];
    // eslint-disable-next-line no-undef
    items: T[] | undefined;
    stickyColumn?: boolean;
    stickyHeaders?: boolean;
    totals?: TableTotal[];
    showExpand?: boolean;
    dark?: boolean;
    darkHeadings?: boolean;
    noResultsMessage?: string;
    isLoading?: boolean;
    totalsAtTop?: boolean;
    hideHeader?: boolean;
}>();

// eslint-disable-next-line no-undef
defineSlots<{
    // eslint-disable-next-line no-undef
    row: (props: { item: T, headers: TableHeader<T>[], showExpand: boolean }) => void;
    // eslint-disable-next-line no-undef
    [key: `header_${string}`]: (props: { header: TableHeader<T> }) => void;
    [key: `total_${string}`]: (props: { total: TableTotal | null, currency?: TableHeader['currency'] }) => void;
    no_result: () => void;
    body: (props: { items: T[], headers: TableHeader<T>[], showExpand: boolean }) => void;
}>();

const columns = computed(() => [
    ...headers.filter((header) => !header.action),
    ...headers.filter((header) => Boolean(header.action))
]);

const noResultsTranslatedMessage = computed(() => noResultsMessage ?? t('general.no_info'));

const emits = defineEmits<{
    'update:sort': [value: Sort<T>];
    'update-sort': [value: TableSort];
    'table-mounted': [value: (el: HTMLTableElement | null) => void];
    'table-unmounted': [value: void];
}>();

const currentSort = computed<TableSort | null>(() => {
    if(sort.value?.slice(1) && sort.value?.slice(0, 1)) {
        return {
            key: sort.value?.slice(1) ?? null,
            order: sort.value?.slice(0,1) === '+' ? SortOrder.Asc : SortOrder.Desc
        };
    }

    return null;
});

// eslint-disable-next-line no-undef
const updateSorting = (header: TableHeader<T>) => {
    if (header.sortable) {
        let order = SortOrder.Asc;
        if (currentSort.value?.key === header.key && currentSort.value.order === SortOrder.Asc) {
            order = SortOrder.Desc;
        }
            
        sort.value = generateSort<T>(header.key as keyof T & string, order);
        emits('update-sort', { key: header.key, order });
    }
};

</script>
