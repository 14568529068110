/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetAdvertiserPublishersInsights200Response } from '../AfClApiModels';
/**
 * AdvertiserPublishersApi - axios parameter creator
 * @export
 */
export const AdvertiserPublishersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Publishers Insights For Advertiser
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {number} [siteGroupId] 
         * @param {'REQUIRES AUDIT' | 'APPROVED' | 'NOT_APPROVED'} [siteStatus] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserPublishersInsights: async (advertiserId: number, siteGroupId?: number, siteStatus?: 'REQUIRES AUDIT' | 'APPROVED' | 'NOT_APPROVED', brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('getAdvertiserPublishersInsights', 'advertiserId', advertiserId)
            const localVarPath = `/v1/advertisers/{advertiserId}/publishers-insights`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (siteStatus !== undefined) {
                localVarQueryParameter['site_status'] = siteStatus;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertiserPublishersApi - functional programming interface
 * @export
 */
export const AdvertiserPublishersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertiserPublishersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Publishers Insights For Advertiser
         * @param {number} advertiserId Numeric ID of the Advertiser
         * @param {number} [siteGroupId] 
         * @param {'REQUIRES AUDIT' | 'APPROVED' | 'NOT_APPROVED'} [siteStatus] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertiserPublishersInsights(advertiserId: number, siteGroupId?: number, siteStatus?: 'REQUIRES AUDIT' | 'APPROVED' | 'NOT_APPROVED', brandIds?: Array<number>, regionIds?: Array<number>, verticalIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdvertiserPublishersInsights200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertiserPublishersInsights(advertiserId, siteGroupId, siteStatus, brandIds, regionIds, verticalIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertiserPublishersApi - factory interface
 * @export
 */
export const AdvertiserPublishersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertiserPublishersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Publishers Insights For Advertiser
         * @param {AdvertiserPublishersApiGetAdvertiserPublishersInsightsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserPublishersInsights(requestParameters: AdvertiserPublishersApiGetAdvertiserPublishersInsightsRequest, options?: AxiosRequestConfig): AxiosPromise<GetAdvertiserPublishersInsights200Response> {
            return localVarFp.getAdvertiserPublishersInsights(requestParameters.advertiserId, requestParameters.siteGroupId, requestParameters.siteStatus, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAdvertiserPublishersInsights operation in AdvertiserPublishersApi.
 * @export
 * @interface AdvertiserPublishersApiGetAdvertiserPublishersInsightsRequest
 */
export interface AdvertiserPublishersApiGetAdvertiserPublishersInsightsRequest {
    /**
     * Numeric ID of the Advertiser
     * @type {number}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly advertiserId: number

    /**
     * 
     * @type {number}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {'REQUIRES AUDIT' | 'APPROVED' | 'NOT_APPROVED'}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly siteStatus?: 'REQUIRES AUDIT' | 'APPROVED' | 'NOT_APPROVED'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertiserPublishersApiGetAdvertiserPublishersInsights
     */
    readonly perPage?: number
}

/**
 * AdvertiserPublishersApi - object-oriented interface
 * @export
 * @class AdvertiserPublishersApi
 * @extends {BaseAPI}
 */
export class AdvertiserPublishersApi extends BaseAPI {
    /**
     * 
     * @summary Get Publishers Insights For Advertiser
     * @param {AdvertiserPublishersApiGetAdvertiserPublishersInsightsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserPublishersApi
     */
    public getAdvertiserPublishersInsights(requestParameters: AdvertiserPublishersApiGetAdvertiserPublishersInsightsRequest, options?: AxiosRequestConfig) {
        return AdvertiserPublishersApiFp(this.configuration).getAdvertiserPublishersInsights(requestParameters.advertiserId, requestParameters.siteGroupId, requestParameters.siteStatus, requestParameters.brandIds, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
