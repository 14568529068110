/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
/**
 * AdvertiserSitesApi - axios parameter creator
 * @export
 */
export const AdvertiserSitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get advertiser\'s publishers sites basic pair
         * @param {number} advertiserId Numeric ID of the advertiser
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [trafficType] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserSitesBasicPair: async (advertiserId: number, regionIds?: Array<number>, verticalIds?: Array<number>, trafficType?: 'PAID' | 'ORGANIC' | 'INTEGRATION', name?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserId' is not null or undefined
            assertParamExists('getAdvertiserSitesBasicPair', 'advertiserId', advertiserId)
            const localVarPath = `/v1/advertisers/{advertiserId}/sites/basic-pair`
                .replace(`{${"advertiserId"}}`, encodeURIComponent(String(advertiserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (trafficType !== undefined) {
                localVarQueryParameter['traffic_type'] = trafficType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertiserSitesApi - functional programming interface
 * @export
 */
export const AdvertiserSitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertiserSitesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get advertiser\'s publishers sites basic pair
         * @param {number} advertiserId Numeric ID of the advertiser
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [trafficType] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertiserSitesBasicPair(advertiserId: number, regionIds?: Array<number>, verticalIds?: Array<number>, trafficType?: 'PAID' | 'ORGANIC' | 'INTEGRATION', name?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertiserSitesBasicPair(advertiserId, regionIds, verticalIds, trafficType, name, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertiserSitesApi - factory interface
 * @export
 */
export const AdvertiserSitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertiserSitesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get advertiser\'s publishers sites basic pair
         * @param {AdvertiserSitesApiGetAdvertiserSitesBasicPairRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserSitesBasicPair(requestParameters: AdvertiserSitesApiGetAdvertiserSitesBasicPairRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.getAdvertiserSitesBasicPair(requestParameters.advertiserId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.trafficType, requestParameters.name, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAdvertiserSitesBasicPair operation in AdvertiserSitesApi.
 * @export
 * @interface AdvertiserSitesApiGetAdvertiserSitesBasicPairRequest
 */
export interface AdvertiserSitesApiGetAdvertiserSitesBasicPairRequest {
    /**
     * Numeric ID of the advertiser
     * @type {number}
     * @memberof AdvertiserSitesApiGetAdvertiserSitesBasicPair
     */
    readonly advertiserId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserSitesApiGetAdvertiserSitesBasicPair
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdvertiserSitesApiGetAdvertiserSitesBasicPair
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {'PAID' | 'ORGANIC' | 'INTEGRATION'}
     * @memberof AdvertiserSitesApiGetAdvertiserSitesBasicPair
     */
    readonly trafficType?: 'PAID' | 'ORGANIC' | 'INTEGRATION'

    /**
     * 
     * @type {string}
     * @memberof AdvertiserSitesApiGetAdvertiserSitesBasicPair
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdvertiserSitesApiGetAdvertiserSitesBasicPair
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertiserSitesApiGetAdvertiserSitesBasicPair
     */
    readonly perPage?: number
}

/**
 * AdvertiserSitesApi - object-oriented interface
 * @export
 * @class AdvertiserSitesApi
 * @extends {BaseAPI}
 */
export class AdvertiserSitesApi extends BaseAPI {
    /**
     * 
     * @summary Get advertiser\'s publishers sites basic pair
     * @param {AdvertiserSitesApiGetAdvertiserSitesBasicPairRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertiserSitesApi
     */
    public getAdvertiserSitesBasicPair(requestParameters: AdvertiserSitesApiGetAdvertiserSitesBasicPairRequest, options?: AxiosRequestConfig) {
        return AdvertiserSitesApiFp(this.configuration).getAdvertiserSitesBasicPair(requestParameters.advertiserId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.trafficType, requestParameters.name, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
