import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { AdminMediaKeysApiUpdateMediaKeyRequest } from '@/stub';
import { AfClApi } from '@/Services/AfClApi';

export const useMediaKeysUpdate = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (payload: AdminMediaKeysApiUpdateMediaKeyRequest) =>
            await AfClApi.adminMediaKeys.updateMediaKey(payload),
        onSuccess: async () => {
            if(onSuccess) {
                onSuccess();
            }
            await queryClient.invalidateQueries(['media-keys']);
        },
    });
};
