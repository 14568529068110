/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetCorporationReportTotals200Response } from '../AfClApiModels';
// @ts-ignore
import { GetCorporationReports200Response } from '../AfClApiModels';
/**
 * CorporationReportsApi - axios parameter creator
 * @export
 */
export const CorporationReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Reports
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [zeroRevenue] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCorporationReports: async (corporationId: number, from: string, to: string, siteGroupId?: number, brandIds?: Array<number>, zeroRevenue?: boolean, sort?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('exportCorporationReports', 'corporationId', corporationId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportCorporationReports', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportCorporationReports', 'to', to)
            const localVarPath = `/v1/corporations/{corporationId}/reports/export`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (zeroRevenue !== undefined) {
                localVarQueryParameter['zero_revenue'] = zeroRevenue;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Report Totals
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {string} [previousFrom] 
         * @param {string} [previousTo] 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [zeroRevenue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorporationReportTotals: async (corporationId: number, from: string, to: string, previousFrom?: string, previousTo?: string, siteGroupId?: number, brandIds?: Array<number>, zeroRevenue?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('getCorporationReportTotals', 'corporationId', corporationId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getCorporationReportTotals', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getCorporationReportTotals', 'to', to)
            const localVarPath = `/v1/corporations/{corporationId}/reports/totals`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (previousFrom !== undefined) {
                localVarQueryParameter['previous_from'] = previousFrom;
            }

            if (previousTo !== undefined) {
                localVarQueryParameter['previous_to'] = previousTo;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (zeroRevenue !== undefined) {
                localVarQueryParameter['zero_revenue'] = zeroRevenue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Reports
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [zeroRevenue] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorporationReports: async (corporationId: number, from: string, to: string, siteGroupId?: number, brandIds?: Array<number>, zeroRevenue?: boolean, sort?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('getCorporationReports', 'corporationId', corporationId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getCorporationReports', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getCorporationReports', 'to', to)
            const localVarPath = `/v1/corporations/{corporationId}/reports`
                .replace(`{${"corporationId"}}`, encodeURIComponent(String(corporationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (siteGroupId !== undefined) {
                localVarQueryParameter['site_group_id'] = siteGroupId;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (zeroRevenue !== undefined) {
                localVarQueryParameter['zero_revenue'] = zeroRevenue;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorporationReportsApi - functional programming interface
 * @export
 */
export const CorporationReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorporationReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Reports
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [zeroRevenue] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCorporationReports(corporationId: number, from: string, to: string, siteGroupId?: number, brandIds?: Array<number>, zeroRevenue?: boolean, sort?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCorporationReports(corporationId, from, to, siteGroupId, brandIds, zeroRevenue, sort, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Report Totals
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {string} [previousFrom] 
         * @param {string} [previousTo] 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [zeroRevenue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCorporationReportTotals(corporationId: number, from: string, to: string, previousFrom?: string, previousTo?: string, siteGroupId?: number, brandIds?: Array<number>, zeroRevenue?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporationReportTotals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCorporationReportTotals(corporationId, from, to, previousFrom, previousTo, siteGroupId, brandIds, zeroRevenue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Reports
         * @param {number} corporationId Numeric ID of the Corporation to find
         * @param {string} from 
         * @param {string} to 
         * @param {number} [siteGroupId] 
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [zeroRevenue] 
         * @param {string} [sort] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCorporationReports(corporationId: number, from: string, to: string, siteGroupId?: number, brandIds?: Array<number>, zeroRevenue?: boolean, sort?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCorporationReports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCorporationReports(corporationId, from, to, siteGroupId, brandIds, zeroRevenue, sort, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorporationReportsApi - factory interface
 * @export
 */
export const CorporationReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorporationReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Reports
         * @param {CorporationReportsApiExportCorporationReportsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCorporationReports(requestParameters: CorporationReportsApiExportCorporationReportsRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.exportCorporationReports(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.siteGroupId, requestParameters.brandIds, requestParameters.zeroRevenue, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Report Totals
         * @param {CorporationReportsApiGetCorporationReportTotalsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorporationReportTotals(requestParameters: CorporationReportsApiGetCorporationReportTotalsRequest, options?: AxiosRequestConfig): AxiosPromise<GetCorporationReportTotals200Response> {
            return localVarFp.getCorporationReportTotals(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.previousFrom, requestParameters.previousTo, requestParameters.siteGroupId, requestParameters.brandIds, requestParameters.zeroRevenue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Reports
         * @param {CorporationReportsApiGetCorporationReportsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorporationReports(requestParameters: CorporationReportsApiGetCorporationReportsRequest, options?: AxiosRequestConfig): AxiosPromise<GetCorporationReports200Response> {
            return localVarFp.getCorporationReports(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.siteGroupId, requestParameters.brandIds, requestParameters.zeroRevenue, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for exportCorporationReports operation in CorporationReportsApi.
 * @export
 * @interface CorporationReportsApiExportCorporationReportsRequest
 */
export interface CorporationReportsApiExportCorporationReportsRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly corporationId: number

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly to: string

    /**
     * 
     * @type {number}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly zeroRevenue?: boolean

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CorporationReportsApiExportCorporationReports
     */
    readonly perPage?: number
}

/**
 * Request parameters for getCorporationReportTotals operation in CorporationReportsApi.
 * @export
 * @interface CorporationReportsApiGetCorporationReportTotalsRequest
 */
export interface CorporationReportsApiGetCorporationReportTotalsRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly corporationId: number

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly previousFrom?: string

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly previousTo?: string

    /**
     * 
     * @type {number}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof CorporationReportsApiGetCorporationReportTotals
     */
    readonly zeroRevenue?: boolean
}

/**
 * Request parameters for getCorporationReports operation in CorporationReportsApi.
 * @export
 * @interface CorporationReportsApiGetCorporationReportsRequest
 */
export interface CorporationReportsApiGetCorporationReportsRequest {
    /**
     * Numeric ID of the Corporation to find
     * @type {number}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly corporationId: number

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly to: string

    /**
     * 
     * @type {number}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly siteGroupId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly zeroRevenue?: boolean

    /**
     * 
     * @type {string}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly sort?: string

    /**
     * 
     * @type {number}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CorporationReportsApiGetCorporationReports
     */
    readonly perPage?: number
}

/**
 * CorporationReportsApi - object-oriented interface
 * @export
 * @class CorporationReportsApi
 * @extends {BaseAPI}
 */
export class CorporationReportsApi extends BaseAPI {
    /**
     * 
     * @summary Export Reports
     * @param {CorporationReportsApiExportCorporationReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationReportsApi
     */
    public exportCorporationReports(requestParameters: CorporationReportsApiExportCorporationReportsRequest, options?: AxiosRequestConfig) {
        return CorporationReportsApiFp(this.configuration).exportCorporationReports(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.siteGroupId, requestParameters.brandIds, requestParameters.zeroRevenue, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Report Totals
     * @param {CorporationReportsApiGetCorporationReportTotalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationReportsApi
     */
    public getCorporationReportTotals(requestParameters: CorporationReportsApiGetCorporationReportTotalsRequest, options?: AxiosRequestConfig) {
        return CorporationReportsApiFp(this.configuration).getCorporationReportTotals(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.previousFrom, requestParameters.previousTo, requestParameters.siteGroupId, requestParameters.brandIds, requestParameters.zeroRevenue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Reports
     * @param {CorporationReportsApiGetCorporationReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporationReportsApi
     */
    public getCorporationReports(requestParameters: CorporationReportsApiGetCorporationReportsRequest, options?: AxiosRequestConfig) {
        return CorporationReportsApiFp(this.configuration).getCorporationReports(requestParameters.corporationId, requestParameters.from, requestParameters.to, requestParameters.siteGroupId, requestParameters.brandIds, requestParameters.zeroRevenue, requestParameters.sort, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
