<template>
    <ContainerSection
        class="pt-10"
        accent
    >
        <TitleSection dark>
            Keys
        </TitleSection>
        <BaseTabSection
            v-model="tabSelector.selected"
            dark
            class="py-4 rounded-xl"
            :options="tabSelector.options"
            item-text="text"
            item-value="value"
        />
    </ContainerSection>
    <ContainerSection class="pt-10 pb-20">
        <suspense>
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
            <template #fallback>
                <p class="text-white">
                    {{ $t('general.loading') }}
                </p>
            </template>
        </suspense>
    </ContainerSection>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { OptionPairContract } from '@/Models/Contracts/OptionPairContract';
import TitleSection from '@/Components/Core/TitleSection.vue';
import BaseTabSection from '@/Components/Core/BaseTabSection.vue';
import ContainerSection from '@/Components/Core/ContainerSection.vue';

const router = useRouter();
const route = useRoute();

const tabSelector = reactive<{
    options: OptionPairContract[],
    selected: string;
}>({
    options: [
        {
            text: 'Media',
            value: 'admin.data.keys.media'
        },
        {
            text: 'Campaign',
            value: 'admin.data.keys.campaign'
        },
        {
            text: 'Source',
            value: 'admin.data.keys.source'
        },
    ],
    selected: route.name ? String(route.name) : 'admin.keys.media'
});

watch(() => tabSelector.selected, () => {
    router.push({ name: tabSelector.selected });
});
</script>
