import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { AdminUpsertCampaignKeyRequest } from '@/stub';
import { AfClApi } from '@/Services/AfClApi';

export const useCampaignKeyCreate = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (payload: AdminUpsertCampaignKeyRequest) =>
            await AfClApi.adminCampaignKeys.createCampaignKey({
                adminUpsertCampaignKeyRequest: payload
            }),
        onSuccess: async () => {
            if(onSuccess) {
                onSuccess();
            }
            await queryClient.invalidateQueries(['campaign-keys']);
        },
    });
};
