import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { CampaignKey } from '@/stub';
import { AfClApi } from '@/Services/AfClApi';

export const useCampaignKeyUpdate = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (payload: CampaignKey) =>
            await AfClApi.adminCampaignKeys.updateCampaignKey({
                campaignKeyId: payload.id,
                adminUpsertCampaignKeyRequest: {
                    name: payload.name,
                    end_at: payload.end_at,
                    start_at: payload.start_at
                }
            }),
        onSuccess: async () => {
            if(onSuccess) {
                onSuccess();
            }
            await queryClient.invalidateQueries(['campaign-keys']);
        },
    });
};
